<template>
  <div>
    <div class="product-name">
      <div class="text-product-name">
        {{ productName.toUpperCase() }}
      </div>
    </div>
    <div class="general-info">
      <div class="qr-code">
        <qr-code
          :text="inventoryCode"
          :size="70"
          error-level="L"
        ></qr-code>
      </div>
      <div class="product-cate-price">
        <div class="product-cate-price-box">
          <p class="text-brand-name">{{ brandName.toUpperCase() }}</p>
          <p class="text-batch-code">
            {{ inventoryCode }}
          </p>
          <p class="text-product-price">
            {{ convertPrice(price) }}đ
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertPrice } from '@/utils/common';
import VueQRCodeComponent from 'vue-qrcode-component';

export default {
  props: {
    productName: {
      value: '',
      required: true,
    },
    brandName: {
      value: '',
      required: true,
    },
    categoryName: {
      value: '',
      required: true,
    },
    inventoryCode: {
      value: '',
      required: true,
    },
    price: {
      price: 0,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    convertPrice,
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
  computed: {},
};
</script>

<style scoped>
.product-name {
  padding: 2mm 2mm 0 2mm;
  text-align: center;
  height: 10mm;
  display: flex;
  align-items: center;
  margin-top: 3px;
}
.text-product-name {
  line-height: 12px;
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 11px;
  border-bottom: 1px solid #000;
}
.general-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
}
.qr-code {
  margin-top: 4px;
  margin-left: 16px;
  background-color: #fff;
  height: fit-content;
}
.product-cate-price {
  margin-left: 4px;
  padding-top: 5px;
  width: -webkit-fill-available;
}
.product-cate-price-box {
  border: 0.5px solid black !important;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  margin-right: 10px;
}

.product-cate-price-box p {
  margin-bottom: 0;
}
.text-brand-name {
  color: black;
  font-weight: 700;
  font-size: 10px;
  padding-bottom: 5px;
  line-height: 10px;
}
.text-cate-name {
  color: black;
  font-weight: 500;
  font-size: 8px;
  line-height: 2mm;
}
.text-product-price {
  color: black;
  font-weight: 700;
  font-size: 15px;
  line-height: 2mm;
  padding-top: 20px;
}
.text-batch-code {
  color: black;
  font-weight: 500;
  font-size: 12px;
  line-height: 0mm;
  padding-top: 5px;
}
</style>
