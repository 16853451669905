<template>
  <div class="print-product-qrcode">
    <KTCodePreview v-bind:title="'In mã sản phẩm'">
      <template v-slot:toolbar>
        <b-button
          size="sm"
          variant="primary"
          style="width: max-content"
          @click="printData()"
        >
          <i class="fas fa-print"></i>
          In sản phẩm
        </b-button>
      </template>
      <template v-slot:preview>
        <b-row class="mb-4">
          <b-col md="2">
            <label>Bạn cần in:</label>
            <b-form-select
              class="select-style"
              v-model="selectLimitPrint"
              :options="listTypePrint"
              size="sm"
              value-field="limit"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-table
          bordered
          hover
          :fields="fields"
          :items="listProduct"
          v-if="renderComponent"
        >
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="checkAll"
                size="lg"
                @change="checkAllProduct"
              ></b-form-checkbox>
            </span>
          </template>
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                v-model="row.item.selected"
                @change="clickIndex"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(quantity)="row">
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="row.item.quantity"
              @change="forceRerender"
            ></b-input>
          </template>
        </b-table>
        <hr
          class="hr-text"
          data-content="Xem trước"
          style="font-weight: 600"
        />
        <div id="printMe">
          <div
            v-for="(groupItem, index) in printItems"
            :key="index"
            class="print-row"
          >
            <div
              v-for="(productItem, count) in groupItem"
              :key="count"
            >
              <div>
                <ProductQRCode
                  class="item-print"
                  :productName="productItem.productTradeName"
                  :brandName="productItem.brandName"
                  :categoryName="productItem.categoryName"
                  :inventoryCode="productItem.batchCode"
                  :price="productItem.listedPrice"
                />
              </div>
            </div>
            <br />
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ProductQRCode from '@/view/components/products/ProductQRCode.vue';
import ApiService from '@/core/services/api.service';
import { convertPrice } from '@/utils/common';

export default {
  props: ['data'],
  data() {
    return {
      listProduct: [],
      selectLimitPrint: 2,
      listTypePrint: [
        {
          limit: 2,
          name: 'Khổ 2 tem',
        },
        {
          limit: 3,
          name: 'Khổ 3 tem',
        },
      ],
      renderComponent: true,
      fields: [
        {
          key: 'batchCode',
          label: 'Mã lô',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-left',
        },
        {
          key: 'productTradeName',
          label: 'Sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-left',
        },
        {
          key: 'brandName',
          label: 'Thương hiệu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-left',
        },
        {
          key: 'categoryName',
          label: 'Danh mục',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-left',
        },
        {
          key: 'listedPrice',
          label: 'Giá',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'quantity',
          label: 'SL',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        { key: 'selected', label: '', tdClass: 'text-center' },
      ],
      checkAll: true,
    };
  },
  created() {
    const stockSlipId = this.$route.query.id;
    if (stockSlipId) {
      this.fetchData();
    } else {
      const listItem = this.data.listItem ? this.data.listItem : [];
      this.genData(listItem);
    }
  },
  computed: {
    printItems() {
      const seletedItems = this.listProduct.filter(
        (product) => product.selected,
      );
      if (!seletedItems.length) {
        return [];
      }
      const listProductPrint = [];
      seletedItems.forEach((seletedItem) => {
        const quantity = parseInt(seletedItem.quantity);
        for (let index = 0; index < quantity; index++) {
          listProductPrint.push(seletedItem);
        }
      });
      const numPage = Math.ceil(
        listProductPrint.length / this.selectLimitPrint,
      );
      const items = [];
      for (let page = 1; page <= numPage; page++) {
        const startIndex = this.selectLimitPrint * (page - 1);
        const endIndex = page * this.selectLimitPrint;
        const groupItem = listProductPrint.slice(startIndex, endIndex);
        items.push(groupItem);
      }
      return items;
    },
  },
  methods: {
    convertPrice,
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    printData: function () {
      if (!document.getElementById('printMe')) {
        return;
      }
      const prtHtml = document.getElementById('printMe').innerHTML;

      const width = this.selectLimitPrint === 3 ? 105 : 70;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <link rel="stylesheet" href="main.css" />
          <link rel="stylesheet" media="print" href="print.css" />
          ${stylesHtml}
          <style>
          @page {
            size: ${width}mm 22mm;
            margin: 0;
            }
          @media print {
            html,
            body {
              width: ${width}mm;
              height: 22mm;
              display: block !important;
              font-family: Times New Roman', Times, serif;
            }
            .item-print {
                width: 195px !important;
                -webkit-print-color-adjust: exact;
            }
          }
          </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    fetchData: function () {
      const stockSlipId = this.$route.query.id;
      if (!stockSlipId) {
        return;
      }
      ApiService.get(`product-batch-codes/by-stockslip/${stockSlipId}`).then(
        (response) => {
          this.listProduct = response.data.data.map((data) => {
            return {
              ...data,
              selected: true,
            };
          });
        },
      );
    },
    checkAllProduct() {
      this.listProduct.forEach((product) => {
        product.selected = this.checkAll;
      });
    },
    clickIndex() {
      if (this.checkAll === true) {
        this.checkAll = false;
      } else {
        const selectedItems = this.listProduct.filter(
          (product) => product.selected,
        );
        this.checkAll = selectedItems.length === this.listProduct.length;
      }
    },
    genData(data) {
      ApiService.post('product-batch-codes/by-product', data).then(
        ({ data }) => {
          this.listProduct = data.data.map((data) => {
            return {
              ...data,
              selected: true,
            };
          });
        },
      );
    },
  },
  components: {
    KTCodePreview,
    ProductQRCode,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'In mã sản phẩm', route: '/products/print-product-qrcode' },
    ]);
  },
};
</script>

<style scoped>
.print-row {
  display: flex;
  margin-bottom: 2px 0;
}
.item-print {
  width: 195px !important;
  -webkit-print-color-adjust: exact;
}
</style>

<style lang="scss">
.print-product-qrcode {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 13px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
</style>